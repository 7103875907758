
<div class="row">
	<div class="col-xs-2 col-sm-2 col-md-3 align-self-center d-none">
		<img src="../assets/art/ux.jpg" class="img-fluid mx-auto">
	</div>
	<div class="col-md align-self-center">
		<p>
		  Innovative UX / UI architect, designer, and developer with 20 years of experience within highly competitive markets. Expert in delivering large-scale projects that exceed customer expectations and balance business and technical goals through strategy, requirement specification, design, implementation, and on-time delivery.  Collaborative communicator continually focused on building relationships and promoting synergy across business lines to drive positive change.  Expert in developing and adapting strategies that meet changing needs of customer and market demands and able to customize and introduce tools, programs and systems to enhance company and client environments.
		</p>
	</div>
</div>