

<div class="row bg-blue-grey-900 text-white">
	<div class="container py-3">
		<div class="row">
						<div class="col-md">
						    <h1 class="font-weight-light pt-3">
						      {{ title }}
						    </h1>
					    </div>
						<div class="col-md-7 text-md-right">
						    <div>
								<span>
									<button class="btn btn-lg btn-outline-dark icon icon-sm rounded-circle text-center white  mr-2"
								        (click)="setCurrentSection('99')"
								        tooltip="Home"
								        placement="bottom">
									<h3 class="d-inline"><span class="fa fa-home"></span></h3>
									</button>
									<button class="btn btn-lg btn-outline-dark blue icon icon-sm rounded-circle text-center white mr-2"
									    (click)="setCurrentSection('0')"
									    tooltip="Resume"
									    placement="bottom">
									<h3 class="d-inline"><span class="fa fa-file-word-o"></span></h3>
									</button>
									<button class="btn btn-lg btn-outline-dark amber icon icon-sm rounded-circle text-center white mr-2"
									    (click)="setCurrentSection('1')"
									    tooltip="Skills"
									    placement="bottom">
									<h3 class="d-inline p-0 m-0"><span class="fa fa-cubes"></span></h3>
									</button>
									<button class="btn btn-lg btn-outline-dark orange icon icon-sm rounded-circle text-center white mr-2"
									    (click)="setCurrentSection('2')"
									    tooltip="My Process"
									    placement="bottom">
									<h3 class="d-inline"><i class="fa fa-refresh"></i></h3>
									</button>
									<button class="btn btn-lg btn-outline-dark deep-orange icon icon-sm rounded-circle text-center white mr-2"
									    (click)="setCurrentSection('3')"
									    tooltip="Outcomes"
									    placement="bottom">
									<h3 class="d-inline"><i class="fa fa-hand-pointer-o"></i></h3>
									</button>
									<button class="btn btn-lg btn-outline-dark purple icon icon-sm rounded-circle text-center white mr-2"
									    (click)="setCurrentSection('4')"
									    tooltip="Contact"
									    placement="bottom">
									<h3 class="d-inline"><i class="fa fa-envelope-o"></i></h3>
									</button>
								</span>
						    </div>
						</div>
					</div>
		<!--
		<div class="row">
			<div class="col d-none">
			    <h1 class="font-weight-light p-0">
			      {{ title }}
			    </h1>
			    </div>
			    <div [ngSwitch]="currentSection">
					<div [ngSwitch]="helpSmallNav">
						<div *ngSwitchCase="99"></div>
						<div *ngSwitchCase="0">
							<div class="row">
								<div class="col">
								    <h1 class="font-weight-light p-0">
								      {{ title }}
								    </h1>
							    </div>
								<div class="col-8">
								    <div>
										<span>
											<button class="btn btn-lg btn-outline-dark icon icon-sm rounded-circle text-center white  mr-2"
										        (click)="setCurrentSection('99')"
										        tooltip="Home"
										        placement="bottom">
											<h3 class="d-inline"><span class="fa fa-home"></span></h3>
											</button>
											<button class="btn btn-lg bg-blue-600 icon icon-sm rounded-circle text-center white mr-2"
											    (click)="setCurrentSection('0')"
											    tooltip="Resume"
											    placement="bottom">
											<h3 class="d-inline"><span class="fa fa-file-word-o"></span></h3>
											</button>
											<button class="btn btn-lg bg-amber-600 icon icon-sm rounded-circle text-center white mr-2"
											    (click)="setCurrentSection('1')"
											    tooltip="Skills"
											    placement="bottom">
											<h3 class="d-inline p-0 m-0"><span class="fa fa-cubes"></span></h3>
											</button>
											<button class="btn btn-lg bg-orange-600 icon icon-sm rounded-circle text-center white mr-2"
											    (click)="setCurrentSection('2')"
											    tooltip="Artifacts"
											    placement="bottom">
											<h3 class="d-inline"><i class="fa fa-files-o"></i></h3>
											</button>
											<button class="btn btn-lg bg-deep-orange-600 icon icon-sm rounded-circle text-center white mr-2"
											    (click)="setCurrentSection('3')"
											    tooltip="Prototypes"
											    placement="bottom">
											<h3 class="d-inline"><i class="fa fa-hand-pointer-o"></i></h3>
											</button>
											<button class="btn btn-lg bg-purple-600 icon icon-sm rounded-circle text-center white mr-2"
											    (click)="setCurrentSection('4')"
											    tooltip="Contact"
											    placement="bottom">
											<h3 class="d-inline"><i class="fa fa-envelope-o"></i></h3>
											</button>
										</span>
								    </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		-->
		
	</div>
</div>




 


<div [ngSwitch]="currentSection">
	<div *ngSwitchDefault>
	</div> 
  
	<div *ngSwitchDefault>
		<div class="row bg-fixed ux blue-grey-300">
			<div class="container py-5">
	      		<app-home></app-home>
	      	</div>
	    </div>
	    
	    <div class="row bg-fixed ux bg-blue-600 white pb-5">
			<div class="container">
				<div class="row">
					 <!--- Panel --->
				  <div class="col-sm-12 col-lg-12 text-center">
				  	
				  	<h2 class="fa fa-file-word-o rounded-circle blue-600 text-center shadow bg-white icon" (click)="setCurrentSection('0')"></h2>
				  	<h2>RESUME&nbsp;/&nbsp;CV</h2>
				  	<p>History, experience, and roles</p>
				  	<button class="btn btn-dark" (click)="setCurrentSection('0')">view&nbsp;/&nbsp;download</button>
				  	
				  </div>
				</div>
			</div>
		</div>

      	<div class="row bg-grey-50 pb-5">
			<div class="container">
				<div class="row">
					<div class="col-sm-12 col-md-12 text-center text-md-left">
						<h3 class="font-weight-light py-3 flex-fill text-center">Experience at a glance</h3>
						<hr class="mt-0" />
					</div>
				 

				  <!--- Panel --->
				  <div class="col-md-12 col-lg text-center mb-4">
				  	<div class="card shadow h-100">
				  		<div class="card-body">
				  	<h2 class="fa fa-cubes rounded-circle bg-amber-600 text-center shadow white icon" (click)="setCurrentSection('1')"></h2>
				  	<h3 class="grey-400 mb-4">SKILLS</h3>
				  	<h5 class="font-weight-bold">Knowledge, and abilities</h5>
				  	<p>text</p>
				  </div>
				  	<div class="card-footer">
				  	<button class="btn btn-dark" (click)="setCurrentSection('1')">explore</button>
				  	</div>
				  </div>
				  </div>

				  <!--- Panel --->
				  <div class="col-md-12 col-lg text-center mb-4">
				  	<div class="card shadow h-100">
				  		<div class="card-body">
				  	<h2 class="fa fa-refresh rounded-circle bg-orange-600 text-center shadow white icon" (click)="setCurrentSection('2')"></h2>
				  	<h3 class="grey-400 mb-4">MY PROCESS</h3>
				  	<h5 class="font-weight-bold">How I approach my work</h5>
				  	<p>text</p>
				  </div>
				  	<div class="card-footer">
				  	<button class="btn btn-dark" (click)="setCurrentSection('2')">explore</button>
				  	</div>
				  </div>
				</div>
				  <!--- Panel --->
				  <div class="col-md-12 col-lg text-center mb-4">
				  		<div class="card shadow h-100">
					  		<div class="card-body">
							  	<h2 class="fa fa-hand-pointer-o rounded-circle bg-deep-orange-600 text-center shadow white icon" (click)="setCurrentSection('3')"></h2>
							  	<h3 class="grey-400 mb-4">OUTCOMES</h3>
							  	<h5 class="font-weight-bold">How I solved problems</h5>
							  	<p>Preview previous work and samples of various artifacts and prototypes.</p>
							</div>
						  	<div class="card-footer">
						  	<button class="btn btn-dark" (click)="setCurrentSection('3')">explore</button>
						  	</div>
					  	</div>
					  </div>	
				</div>
		    </div>
		</div>
		<div class="row bg-blue-grey-50 d-none">
			<div class="container pt-5">
				<div class="row">
					<div class="col-sm-12 col-md-12 text-center text-md-left">
						<h3 class="font-weight-light mb-5 flex-fill">My Process</h3>
					</div>
				  <!--- Panel --->
				  <div class="col-sm-6 col-md">

				    <div class="panel panel-title  text-center flex-fill mb-5 help-panel card transparent p-3"
				      (click)="setCurrentSection('0')" style="cursor: pointer;" ng-click="toggleSingleClass()">
				      <div class="panel-heading">
				        <h2 class="fa fa-file-word-o"></h2>
				      </div>
				      <div class="panel-footer">
				        Resume
				      </div>
				    </div>
				  </div>

				  <!--- Panel --->
				  <div class="col-sm-6 col-md">
				    <div class="panel panel-title text-center flex-fill mb-5 help-panel card transparent p-3"
				      (click)="setCurrentSection('1')" style="cursor: pointer;">
				      <div class="panel-heading">
				        <h2 class="fa fa-cubes"></h2>
				      </div>
				      <div class="panel-footer">
				        Skills
				      </div>
				    </div>
				  </div>

				  <!--- Panel --->
				  <div class="col-sm-6 col-md">
				    <div class="panel panel-title text-center flex-fill mb-5 help-panel card transparent p-3"
				      (click)="setCurrentSection('2')" style="cursor: pointer;">
				      <div class="panel-heading">
				        <h2 class="fa fa-refresh"></h2>
				      </div>
				      <div class="panel-footer">
				        My Process
				      </div>
				    </div>
				  </div>

				  <!--- Panel --->
				  <div class="col-sm-6 col-md">
				    <div class="panel panel-title text-center flex-fill mb-5 help-panel card transparent p-3"
				      (click)="setCurrentSection('3')" style="cursor: pointer;">
				      <div class="panel-heading">
				        <h2 class="fa fa-hand-pointer-o"></h2>
				      </div>
				      <div class="panel-footer">
				        Outcomes
				      </div>
				    </div>
				  </div>
				</div>
		    </div>
		</div>
		<div class="row bg-grey-100">

			<div class="container">
			<hr class="margin-top-50">
			<div class="lead text-center">“The world as we have created it, is a process of our thinking. <br>It cannot be changed without changing our thought process.” <br>
				― Albert Einstein</div>
			<hr class="margin-bottom-50">

				</div>
			</div>
		<div class="row bg-blue-grey-50">
			<div class="container py-5">
				  <div class="col-4 mx-auto">
				    <div class="panel panel-title text-center flex-fill help-panel border border-secondary rounded py-3 card-transparent"
				      (click)="setCurrentSection('4')" style="cursor: pointer;">
				      <div class="panel-heading">
				        <h2 class="fa fa-envelope-o"></h2>
				      </div>
				      <div class="panel-footer">
				        Contact
				      </div>
				    </div>
				  </div>
			</div>
		</div>
    </div> 
    <div *ngSwitchCase="0">
    	
    	<div class="row bg-white">
			<div class="container py-5" style="min-height: 40rem;">
		      	<app-resume></app-resume>
		    </div>
  		</div>
  		<div class="row bg-grey-100">

			<div class="container">
			<hr class="margin-top-50">
			<div class="lead text-center">“The only source of knowledge is experience.” <br>
				― Albert Einstein</div>
			<hr class="margin-bottom-50">

				</div>
			</div>
  		
    </div>
    <div *ngSwitchCase="1">
    	
    	<div class="row bg-white">
			<div class="container py-5" style="min-height: 40rem;">
			    <app-skills></app-skills>
			</div>
		</div>
		<div class="row bg-grey-100">

			<div class="container">
			<hr class="margin-top-50">
			<div class="lead text-center">“The value of a man should be seen in what he gives and not in what he is able to receive.” <br>
				― Albert Einstein</div>
			<hr class="margin-bottom-50">

				</div>
			</div>
    </div>
    <div *ngSwitchCase="2">
    	<div class="row bg-white" style="min-height: 40rem;">
			<div class="container py-5">
    			<app-process></app-process>
    		</div>
    	</div>
    	<div class="row bg-grey-100">

			<div class="container">
			<hr class="margin-top-50">
			<div class="lead text-center">“Never let yourself be seduced by any problem, no matter how difficult.” <br>
				― Albert Einstein</div>
			<hr class="margin-bottom-50">

			</div>
		</div>
		<div class="row bg-blue-grey-700 p-5">
			<div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-0 col-lg-6">
				<h3 class="white"> Need to incorporate UX into your agile workflow? </h3>
				<h4 class="white ">Lean UX might help.</h4>
				<p class="font-weight-light cyan-200">Lean UX is an incredibly useful technique when working on projects where the Agile development method is used. Traditional UX techniques often don’t work when development is conducted in rapid bursts – there’s not enough time to deliver UX in the same way. Fundamentally Lean UX and other forms of UX all have the same goal in mind; delivering a great user experience it’s just that the way you work on a project is slightly different. So let’s take a look at how that might work. 
				</p>
				<p class="font-weight-light cyan-200">Lean UX is focused on the experience under design and is less focused on deliverables than traditional UX. It requires a greater level of collaboration with the entire team. The core objective is to focus on obtaining feedback as early as possible so that it can be used to make quick decisions. The nature of Agile development is to work in rapid, iterative cycles and Lean UX mimics these cycles to ensure that data generated can be used in each iteration. </p>
				<h5 class="white pt-3">Useful articles to help get you going.</h5>
				
					<a href="https://www.interaction-design.org/literature/article/a-simple-introduction-to-lean-ux" target="_blank" class="btn btn-light"> Intro to Lean UX</a>
					&nbsp;
					<a href="https://www.oreilly.com/ideas/what-is-lean-ux" target="_blank" class="btn btn-light"> What is Lean UX?</a>
				
			</div>
			<div class="col-sm-12 col-md-6 col-md-offset-0 col-lg-6 pt-5">
				<img src="assets/art/lean-ux-new.png" class="img-fluid center-block" style="border-radius: 20px;">
			</div>
		</div>
    </div>
    <div *ngSwitchCase="3"> 
    	<div class="row bg-white">
			<div class="container py-5" style="min-height: 40rem;">
    			<app-outcomes></app-outcomes>
    		</div>
    	</div>
    	<div class="row bg-grey-100">

			<div class="container">
			<hr class="margin-top-50">
			<div class="lead text-center">“No amount of experimentation can ever prove me right; a single experiment can prove me wrong.” <br>
				― Albert Einstein</div>
			<hr class="margin-bottom-50">

				</div>
			</div>
    </div>
    <div *ngSwitchCase="4"> 
    	<div class="row bg-white">
			<div class="container py-5" style="min-height: 40rem;">
    			<app-contact></app-contact>
    		</div>
    	</div>
    	<div class="row bg-grey-100">

			<div class="container">
			<hr class="margin-top-50">
			<div class="lead text-center">“It’s not that I’m so smart, it’s just that I stay with problems longer.” <br>
				― Albert Einstein</div>
			<hr class="margin-bottom-50">

				</div>
			</div>
    </div>
</div>



<div class="row bg-fixed bg-foot ux d-none d-sm-block">
</div>