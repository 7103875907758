
<div class="row">
	<div class="col-md-5">
<h2><span class="fa fa-envelope-o bg-purple-600 icon rounded-circle text-center white"></span> Contact</h2>
	</div>
	<div class="col-md">
		<h4 class="text-md-right" style="line-height: 5.5rem"><small class="font-weight-light grey-400 ml-3">Great things start with a converstation.</small></h4>
	</div>
</div>
<hr />

<div class="row">
	<div class="col-12">
		<p>email me at:</p> <a href="mailto:donald.heyd@me.com" class="btn btn-dark btn-lg"><span class="fa fa-envelope"></span>  donald.heyd@me.com</a>
	</div>
</div>
