<div class="row">
	<div class="col-md-5">
		<h2>
			<span class="fa fa-refresh bg-orange-600 icon rounded-circle text-center white"></span> My Process
		</h2>
	</div>
	<div class="col-md">
		<h4 class="text-md-right" style="line-height: 5.5rem"><small class="font-weight-light grey-400 ml-3">How I approach my work</small></h4>
	</div>
</div>
<hr />


	
<!-- START -->









			
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12 text-center">
				<div class="mb-5">
					<h3 class="">UX is everywhere.</h3>
					<h4 class="font-weight-light mb-5">I often wear many hats, that is why I integrate UX where ever I am. </h4>
					<div class="text-center mt-3 mb-5 row justify-content-md-center no-gutters" id="teamDef">
							
						<!--
							<div class="bg-blue-grey-800 white shadow icon-box p-3">
								<h2 class="white font-weight-light m-0 p-3">XA</h2>
							</div>
						-->
						<div class="shadow icon-box col" tooltip="Experience Architect">
							<h2 class=" font-weight-light">XA</h2>
						</div>

						<div class="shadow icon-box col" tooltip="Business Analyst">
							<h2 class=" font-weight-light">BA</h2>
						</div>
						<div class="shadow icon-box col" tooltip="Experience Designer">
							<h2 class=" font-weight-light">UX</h2>
						</div>
						<div class="shadow icon-box col" tooltip="Visual Designer">
							<h2 class=" font-weight-light">VX</h2>
						</div>
						<div class="shadow icon-box col" tooltip="UI Designer">
							<h2 class=" font-weight-light">UI</h2>
						</div>
						<div class="shadow icon-box col" tooltip="Quality Assurance">
							<h2 class=" font-weight-light">QA</h2>
						</div>
					</div>
					<!-- TO DO -  update content -->
					<p>We are all humans, which by default, qualifies us to be UX experts. We use UX everyday, it's in everything we do, and in everything we use to conduct life.</p>
					<p>A key ingredient to appling this to software development, is for everyone to always consider what experiences we are creating for the users by the small decisions we make at each step of the way. Always trying to roll up our work into the greater context of the application and its purpose. <br>We should always be asking "how will this impact the user?".</p>
					<p>Though the actual UX work requires specific skill sets to execute effectivly, the UX process really represents problem solving. Each discipline may be solving different types of problems, but the concept of solving with the user in mind is what matters, we must always aim to solve more than the "technology" problem.<br> Technology is how we implement solving the "user" problem.
					</p>
				</div>
			</div>
			<div class=" col-sm-12 col-md-12 text-center">
				<h2 class=""> Here is how I do it...</h2>
				<h4 class="font-weight-light mb-4"> as part of the team. </h4>
				<img src="assets/art/ux-process-cycle.png" class="img-fluid mt-3">
			
				<hr class="my-5">

				<!--
				<h3 class=""> Distributed UX</h3>
				<p>Based on your role, here are some tasks where you help ensure a sucessful user experience.</p>
				<img src="assets/art/ux-process-lanes.png" class="img-fluid">
				<p>Everything doesn't happen at once. We are often at different steps throughout the process. We should always consider the downstream effect in our work. A postive user experience is built upon a clear understanding of the objectives by all disciplines at each step in the process.</p>
				<img src="assets/art/ux-process-handoff.png" class="img-fluid" style="margin-left: 3px;">
				<hr>
				-->

				<h2 class=""> Workflow and integration points</h2>
				<h4 class="font-weight-light mb-4"> The handoff is the key. </h4>
				<img src="assets/art/process-loop-model.png" class="img-fluid mt-3" style="margin-left: 3px;">
			</div>
		</div>			





	






<!-- END -->