import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outcomes',
  templateUrl: './outcomes.component.html',
  styleUrls: ['./outcomes.component.css']
})
export class OutcomesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
