import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ViewManagerComponent } from './view-manager/view-manager.component';
import { HomeComponent } from './home/home.component';
import { ResumeComponent } from './resume/resume.component';
import { SkillsComponent } from './skills/skills.component';
import { ArtifactsComponent } from './artifacts/artifacts.component';
import { PrototypesComponent } from './prototypes/prototypes.component';
import { ContactComponent } from './contact/contact.component';
import { ModalModule } from 'ngx-bootstrap';

import { TooltipModule } from 'ngx-bootstrap';
import { ModalComponent } from './modal/modal.component';
import { AdvantageComponent } from './prototypes/advantage/advantage.component';
import { ProcessComponent } from './process/process.component';
import { OutcomesComponent } from './outcomes/outcomes.component';


@NgModule({
  declarations: [
    AppComponent,
    ViewManagerComponent,
    HomeComponent,
    ResumeComponent,
    SkillsComponent,
    ArtifactsComponent,
    PrototypesComponent,
    ContactComponent,
    ModalComponent,
    AdvantageComponent,
    ProcessComponent,
    OutcomesComponent
    
  ],
  imports: [
    BrowserModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
