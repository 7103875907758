import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-manager',
  templateUrl: './view-manager.component.html',
  styleUrls: ['./view-manager.component.css']
})
export class ViewManagerComponent implements OnInit {
title = 'donald heyd';

  constructor() { }

   helpSmallNav;
    currentSection;
   


    ngOnInit() { }

  setCurrentSection(view) {

    if (view < 10) { this.helpSmallNav = 0; } else { this.helpSmallNav = 99; }

    this.currentSection = view;

  }

}
