<div class="row">
	<div class="col-md-5">
<h2><span class="fa fa-file-word-o bg-blue-600 icon rounded-circle text-center white"></span> Resume</h2>
	</div>
	<div class="col-md">
		<h4 class="text-md-right pt-3"><small class="font-weight-light grey-400 ml-3">History, experience, and roles</small><br />
		<a href="#" class="btn btn-sm btn-outline-secondary mt-2">Download Resume</a> &nbsp;
		<a href="http://www.linkedin.com/in/donald-heyd-73197552" target="blank" class="btn btn-sm btn-outline-secondary mt-2">View LinkedIn Profile</a>
	</h4>

	</div>
</div>
<hr />

<div class="row">
	<div class="col-12">
		<ul class="timeline">
		    <li class="event" data-date="2016-Present">
		      <h3>Enterprise UX/UI Architect</h3>
		      <h5>Leidos</h5>
		      <h6>Arlingtion, Virginia </h6>
		      <p>
		      	Responsible for complete design of GSA Advantage eCommerce site, very large scale ecommerce environment, and sub sites including client side software systems part of GSA Advantage suite of tools for private sector contractors and federal employees. Other projects include GSA Acquisition Gateway, Apps.gov, eBuy, eLibrary, GWAC portal, and ITSN portal. Provide technical oversight and guidance, guiding client through the decision-making process to implement best approach for their system and objectives, user experience, and UI architecture. Design and build all core UI components to include all visual aspects, all page designs and layouts including user interactions and workflows. 
		      </p>
		    </li>
		    <li class="event" data-date="2009-2016">
		      <h3>Sr. UX/UI Architect</h3>
		      <h5>Lockheed Martin</h5>
		      <h6>Arlingtion, Virginia </h6>
		      <p>
		      	Responsible for complete design of GSA Advantage eCommerce site, very large scale ecommerce environment, and sub sites including client side software systems part of GSA Advantage suite of tools for private sector contractors and federal employees. Other projects include GSA Acquisition Gateway, Apps.gov, eBuy, eLibrary, GWAC portal, and ITSN portal. Provide technical oversight and guidance, guiding client through the decision-making process to implement best approach for their system and objectives, user experience, and UI architecture. Design and build all core UI components to include all visual aspects, all page designs and layouts including user interactions and workflows. 
		      </p>    
		    </li>
		    <li class="event" data-date="2005-2009">
		      <h3>UX/UI Architect, Designer & Creative Director</h3>
		      	<h5>Independent Contractor/Freelance</h5>
		      <h6>Northern Virginia </h6>		      
		      <p>
		      	Worked directly with clients to capture requirements and create functional demonstrations ensuring accuracy. Responsible for all UI design based on sound UX principles including data and content organization for development of intuitive navigation systems and positive user experiences.  Determined and created all graphics and icons ensuring effective visual communication. Produced graphic sketches, designs, and copy layouts for online and print content.  Created logos and branding and adhered to any existing brand guidelines.  Implemented Web design parameters, style guides and visual standards.  Established and produced graphic material for use in advertising and promotion of online and print services and products.  Provided guidance and expertise on design for all multimedia and print products.  Responsible for creation and management of all CSS for projects.  Designed and developed Flash media elements including interactive media for training courseware.  Managed Video/Audio production and editing.  
		      </p>    
		    </li>
		    <li class="event" data-date="2004-2005">
		      <h3>UX/UI Architect, Designer & Developer</h3>
		      	<h5>COMTek</h5>
		      <h6>Chantilly, Virginia </h6>		      
		      <p>
		      	Worked closely with Marketing developing and implementing web strategies and solutions.  Managed team of two designers.  Developed and managed all company and client websites.  Generated weekly analytical reports for all managed websites.  Worked closely with clients establishing graphical user interfaces.  Identified, created, and managed development of all media elements to include all marketing and corporate collateral.
		      </p>    
		    </li>
		    <li class="event" data-date="2002-2004">
		      <h3>Technical Director</h3>
		      	<h5>Northrop Grumman </h5>
		      <h6>McLean, Virginia </h6>		      
		      <p>
		      	Managed production process of computer-based training courses for DoD including resource scheduling and planned and mentored production group consisting of Flash Developers, Web Programmers, and Graphic Artists.  Coordinated with Instructional Design group providing technical knowledge for development of courseware.  Developed and implemented organizational processes for groups gaining efficiencies in production process.  Directed branding, design, development, and implementation of all user interfaces in both HTML and Flash for WBT/CBT courseware.  Provided technical expertise to government clients in areas of 508 compliance and SCORM conformance.  Managed subcontractor development efforts and coordinated with client on all aspects of project and liaised with subcontractor.  Provided guidance to organization regarding current and changing trends in industry.  
		      </p>    
		    </li>
		    <li class="event" data-date="2000-2002">
		      <h3>Senior Media Designer/Web Designer</h3>
		      	<h5>C² Technologies, Inc </h5>
		      <h6>Vienna, Virginia </h6>		      
		      <p>
		      	Coordinated, supervised, and provided multimedia elements to include graphics, animations, audio, Flash Action Scripting, and interactions support for CD-ROM and Web-based training products.  Determined approach, set guidelines, and designed and implemented Graphical User Interfaces for all projects.  Produced graphic sketches, designs, and copy layouts for online and print content.  Determined size and arrangement of illustrative material and copy, selected style and size of type.  Arranged layout based upon available space, knowledge of layout principles, and aesthetic design concepts.  Determined look-and-feel of all CBT and Web-based projects.  Created original graphics and icons for use in projects.  Coordinated and met with clients for requirements and reviews.  Coordinated and executed photo shoots. 
		      </p>    
		    </li>
		    <li class="event" data-date="1997-2000">
		      <h3>UX/UI Architect, Designer & Developer</h3>
		      	<h5>Independent Contractor/Freelance </h5>
		      <h6>Northern Virginia </h6>		      
		      <p>
		      	Work directly with clients capturing requirements and creating functional demonstrations to ensure accuracy. Responsible for all UI design based on sound UX principles including data and content organization for development of intuitive navigation systems and positive user experiences.  Created mockups, wireframes, and rapid functional prototypes in HTML, CSS, and JavaScript for demonstration and communication of approach to client. Determined approach and using wire frames to set guidelines, design, and implement Graphical User Interfaces for all projects.  Implemented sound mobile approach and standards.  Produced graphic material for use in advertising and promotion. Responsible for creation and management of all CSS for projects.  Managed Video/Audio production and editing.
		      </p>    
		    </li>
		</ul>
	</div>
	
</div>
