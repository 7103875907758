
<div class="row">
	<div class="col-md-5">
<h2><span class="fa fa-hand-pointer-o  bg-deep-orange-600 icon rounded-circle text-center white"></span> Outcomes</h2>
	</div>
	<div class="col-md">
		<h4 class="text-md-right" style="line-height: 5.5rem"><small class="font-weight-light grey-400 ml-3">How I solved problems</small></h4>
	</div>
</div>
<hr />

<div class="row no-gutters">
	<div class="col-sm-12 col-md-6 col-lg-4">
		<div class="card shadow bg-blue-grey-800 white">
			<div class="card-img-container">
				<img class="img-fluid" src="../assets/art/temp-screen.png" class="img-fluid" alt="...">
			</div>
		  <div class="card-body">
		    <h5 class="card-title m-0">ADVANTAGE</h5>
		    <small>General Services Administration</small>
		  </div>
		  <div class="card-footer">
		  	<button type="button" class="btn btn-outline-secondary btn-sm btn-block" (click)="advantage.show()">view details</button>
		  </div>
		</div>
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		<div class="card shadow bg-blue-grey-800 white">
			<div class="card-img-container">
				<img class="img-fluid" src="../assets/samples/ebuy/ebuy-1.png" class="img-fluid" alt="...">
			</div>
		  <div class="card-body">
		    <h5 class="card-title m-0">eBUY</h5>
		    <small>General Services Administration</small>
		  </div>
		  <div class="card-footer">
		  	<button type="button" class="btn btn-outline-secondary btn-sm btn-block" (click)="advantage.show()">view details</button>
		  </div>
		</div>
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		<div class="card shadow bg-blue-grey-800 white">
			<div class="card-img-container">
				<img class="img-fluid" src="../assets/samples/itsn/itsn-1.png" class="img-fluid" alt="...">
			</div>
		  <div class="card-body bg-blue-grey-800 white bg-dark">
		    <h5 class="card-title m-0">IT NAVIGATOR</h5>
		    <small>General Services Administration</small>
		  </div>
		  <div class="card-footer">
		  	<button type="button" class="btn btn-outline-secondary btn-sm btn-block" (click)="advantage.show()">view details</button>
		  </div>
		</div>
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		<div class="card shadow bg-blue-grey-800 white">
			<div class="card-img-container">
				<img class="img-fluid" src="../assets/samples/fedidcard/fedidcard-1.png" class="img-fluid" alt="...">
			</div>
		  <div class="card-body">
		    <h5 class="card-title m-0">FED ID CARD</h5>
		    <small>General Services Administration</small>
		  </div>
		  <div class="card-footer">
		  	<button type="button" class="btn btn-outline-secondary btn-sm btn-block" (click)="advantage.show()">view details</button>
		  </div>
		</div>
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		<div class="card shadow bg-blue-grey-800 white">
			<div class="card-img-container">
				<img class="img-fluid" src="../assets/samples/interact/interact-1.png" class="img-fluid" alt="...">
			</div>
		  <div class="card-body">
		    <h5 class="card-title m-0">INTERACT</h5>
		    <small>General Services Administration</small>
		  </div>
		  <div class="card-footer">
		  	<button type="button" class="btn btn-outline-secondary btn-sm btn-block" (click)="advantage.show()">view details</button>
		  </div>
		</div>
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		<div class="card shadow bg-blue-grey-800 white">
			<div class="card-img-container">
				<img class="img-fluid" src="../assets/samples/gwac/gwac-1.png" class="img-fluid" alt="...">
			</div>
		  <div class="card-body">
		    <h5 class="card-title m-0">SCOPE REVIEW</h5>
		    <small>General Services Administration</small>
		  </div>
		  <div class="card-footer">
		  	<button type="button" class="btn btn-outline-secondary btn-sm btn-block" (click)="advantage.show()">view details</button>
		  </div>
		</div>
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		<div class="card shadow bg-blue-grey-800 white">
			<div class="card-img-container">
				<img class="img-fluid" src="../assets/samples/ag/ag-1.png" class="img-fluid" alt="...">
			</div>
		  <div class="card-body">
		    <h5 class="card-title m-0">ACQUISITION GATEWAY</h5>
		    <small>General Services Administration</small>
		  </div>
		  <div class="card-footer">
		  	<button type="button" class="btn btn-outline-secondary btn-sm btn-block" (click)="advantage.show()">view details</button>
		  </div>
		</div>
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		<div class="card shadow bg-blue-grey-800 white">
			<div class="card-img-container">
				<img class="img-fluid" src="../assets/samples/csr/csr-1.png" class="img-fluid" alt="...">
			</div>
		  <div class="card-body">
		    <h5 class="card-title m-0">CUSTOMER SERVICE PORTAL</h5>
		    <small>Consumer Reports</small>
		  </div>
		  <div class="card-footer">
		  	<button type="button" class="btn btn-outline-secondary btn-sm btn-block" (click)="advantage.show()">view details</button>
		  </div>
		</div>
	</div>
	


	<!--
	<div class="col-sm-12 col-md-6 col-lg-4">
		GSA NAVIGATOR - IT solution finder tool
		Role: UX architect, UI Architect, design, design implementation 
		Day 1 prototype:
		http://itsn-gsa.donaldheyd.com
		Final production:
		https://navigator.gsa.gov/app/#/
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		GSA FED ID CARD - portal for GSA badge management and acquisition
		Role: UX architect, UI Architect, design, design implementation 
		prototype: http://fic.donaldheyd.com/
		production: https://www.fedidcard.gov/
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		ACQUISITION GATEWAY - Contracting workspace
		Role: UX architect, UI Architect, design, design implementation 
		https://hallways.cap.gsa.gov
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		GSA INTERACT - gsa social platform future state
		Role: UX architect, UI Architect, design, design implementation 
		http://interact2017.donaldheyd.com/
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		CONSUMER REPORTS CUSTOMER SERVICE PORTAL - Customer service management portal
		Role: UX architect, UI Architect, design, design implementation 
		http://csr-ui.donaldheyd.com/final-dashboard.html 
		http://cr.donaldheyd.com/
	</div>
	<div class="col-sm-12 col-md-6 col-lg-4">
		GSA SCOPE REVIEW - Contract review management tool
		Role: UX architect, UI Architect, design, design implementation 
		http://gwac.donaldheyd.com
	</div>
	-->


	
</div>





<div bsModal #advantage="bs-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog right">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title p-0">GSA Advantage</h4>
        <div class="close pull-right" (click)="advantage.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </div>
      </div>
      <div class="modal-body">
        <app-advantage></app-advantage>
      </div>
      <div class="modal-footer text-right">
        <button class="btn btn-primary" (click)="advantage.hide()">Close</button>
      </div>
    </div>
  </div>
</div>