
<div class="row">
	<div class="col-md-5">
		<h2><span class="fa fa-cubes bg-amber-600 icon rounded-circle text-center white"></span> Skills</h2> 
	</div>
	<div class="col-md">
		<h4 class="text-md-right" style="line-height: 5.5rem"><small class="font-weight-light grey-400 ml-3">Knowledge, and abilities</small></h4>
	</div>
</div>
<hr />
<div class="container">
<div class="row justify-content-md-center">

<!--	
<div class="col-sm-12">
	<ul class="timeline">
	    <li class="event" data-date="Leadership">
		    <ul class="mb-sm-0 mb-md-4">
				<li>Thought leader</li>
				<li>Creative direction</li>
				<li>Process and workflow design</li>
				<li>Cross-functional coordination</li>
				<li>Team mentoring</li>
			</ul>
	    </li>
	    <li class="event" data-date="User Experience">
	    	<ul class="mb-sm-0 mb-md-4">
				<li>Architecture</li>
				<li>Research</li>
				<li>Facilitation</li>
				<li>Design</li>
				<li>Testing</li>
			</ul>
	    </li>
	    <li class="event" data-date="User Interface">
	    	<ul class="mb-sm-0 mb-md-4">
				<li>Architecture</li>
				<li>Design</li>
				<li>Information architecture</li>
				<li>Style guide</li>
				<li>Interaction design</li>
			</ul>
	    </li>
	    <li class="event" data-date="Visual Design">
	    	<ul class="mb-sm-0 mb-md-4">
				<li>Web</li>
				<li>Print</li>
				<li>Video</li>
				<li>Branding</li>
				<li>Illustration</li>
				<li>Info Graphics</li>
			</ul>
	    </li>
	    <li class="event" data-date="Technology">
	    	<ul class="mb-sm-0 mb-md-4">
				<li>HTML</li>
				<li>CSS</li>
				<li>JS</li>
				<li>Jquery</li>
				<li>AngualarJS</li>
				<li>Bootstrap</li>
			</ul>
	    </li>
	    <li class="event" data-date="Software">
	    	<ul class="mb-sm-0 mb-md-4">
				<li>Photoshop</li>
				<li>Illustrator</li>
				<li>InDesign</li>
				<li>Adobe Experience Designer</li>
				<li>Sketch</li>
			</ul>
	    </li>
	</ul>
</div>	
-->

<div class="col-md-6 col-lg-4">
	<h3>Leadership</h3>
	<ul class="mb-sm-0 mb-md-4">
		<li>Thought leader</li>
		<li>Creative direction</li>
		<li>Process and workflow design</li>
		<li>Cross-functional coordination</li>
		<li>Team mentoring</li>
	</ul>
	<hr class="mt-4 mb-3  d-md-none" />
</div>

<div class="col-md-6 col-lg-4">
	<h3>User Experience</h3>
	<ul class="mb-sm-0 mb-md-4">
		<li>Architecture</li>
		<li>Research</li>
		<li>Facilitation</li>
		<li>Design</li>
		<li>Testing</li>
	</ul>
	<hr class="mt-4 mb-3  d-md-none" />
</div>

<div class="col-md-6 col-lg-4">
	<h3>User Interface</h3>
	<ul class="mb-sm-0 mb-md-4">
		<li>Architecture</li>
		<li>Design</li>
		<li>Information architecture</li>
		<li>Style guide</li>
		<li>Interaction design</li>
	</ul>
	<hr class="mt-4 mb-3  d-md-none" />
</div>

<div class="col-md-6 col-lg-4">
	<h3>Visual Design</h3>
	<ul class="mb-sm-0 mb-md-4">
		<li>Web</li>
		<li>Print</li>
		<li>Video</li>
		<li>Branding</li>
		<li>Illustration</li>
		<li>Info Graphics</li>
	</ul>
</div>

<div class="col-md-6 col-lg-4">
	<h3>Software</h3>
	<ul class="mb-sm-0 mb-md-4">
		<li>Photoshop</li>
		<li>Illustrator</li>
		<li>InDesign</li>
		<li>Adobe Experience Designer</li>
		<li>Sketch</li>
	</ul>
	<hr class="mt-4 mb-3  d-md-none" />
</div>
<div class="col-md-6 col-lg-4">
	<h3>Technology</h3>
	<ul class="mb-sm-0 mb-md-4">
		<li>HTML</li>
		<li>CSS</li>
		<li>JS</li>
		<li>Jquery</li>
		<li>AngualarJS</li>
		<li>Bootstrap</li>
	</ul>
	<hr class="mt-4 mb-3  d-md-none" />
</div>


</div>
</div>